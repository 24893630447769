.table td {
    text-align: center;   
 }

 .table th {
    text-align: center;   
 }

 .biggercheckbox {
     width: 30px;
     height: 30px
 }

 .denied {
   background: #ff6969
 }

 .approved {
   background: #a8ff90
 }

 .alarmrow {
    -webkit-animation: alarm-animation 2s infinite;  /* Safari 4+ */
    -moz-animation: alarm-animation 2s infinite;  /* Fx 5+ */
    -o-animation: alarm-animation 2s infinite;  /* Opera 12+ */
    animation: alarm-animation 2s infinite;  /* IE 10+, Fx 29+ */
  }
  
  @keyframes alarm-animation {
    0%, 49% {
      background-color: inherit;
    }
    50%, 100% {
      background-color: #7532a8;
    }
  }

  .priorityrow{
    -webkit-animation: priority-animation 2s infinite;  /* Safari 4+ */
    -moz-animation: priority-animation 2s infinite;  /* Fx 5+ */
    -o-animation: priority-animation 2s infinite;  /* Opera 12+ */
    animation: priority-animation 2s infinite;  /* IE 10+, Fx 29+ */
  }

  @keyframes priority-animation {
    0%, 49% {
      background-color: inherit;
    }
    50%, 100% {
      background-color: #e50000;
    }
  }

 img {
     max-width: 50px;
     max-height: 35px;
 }